import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { postEvent } from 'src/api'
import { ReactComponent as NoTable } from 'src/assets/ico-table-clear-24.svg'
import { ReactComponent as TableVector } from 'src/assets/ico-qr.svg'
import { useTranslation } from 'src/hooks/useTranslation'
import { getLang, setLang } from 'src/state/local'
import { getTableNum, getVenueId, setTableNum } from 'src/state/location'
import { selectLanguages } from 'src/state/texts'
import { getUserData } from 'src/state/user/selectors'
import { getVenueById } from 'src/state/venues'
import CodeScan from 'src/components/CodeScan'
import { MenuBar } from 'src/components/ui/MenuBar'
import Background from 'src/components/ui/Background.tsx'
import useAppPreferences from 'src/hooks/useAppPreferences'
import useThemeStyles from 'src/hooks/useThemeStyles'
import useCheckNative from 'src/hooks/useCheckNative'
import useCustomPages from 'src/hooks/useCustomPages'
import { SetButton } from './components/SetButton'
import SettingsIcon from './components/SettingsIcon'
import s from './styles.module.scss'

const Settings = () => {
  const history = useHistory()
  const { preferences, telegram, phone } = useAppPreferences()
  const { variables } = useThemeStyles()
  const { demoVariables } = useSelector(state => state.demo)
  const { t } = useTranslation()
  const [scan, setScan] = useState(false)
  const dispatch = useDispatch()
  const tableNum = useSelector(getTableNum)
  const venueId = useSelector(getVenueId)
  const user = useSelector(getUserData)
  const venue = useSelector(getVenueById)(venueId)
  const userLang = useSelector(getLang)
  const languages = useSelector(selectLanguages)
  const isNative = useCheckNative()

  const customPages = useCustomPages()

  /**
   * если у пользователя установлен язык системы отсутсвующй у нас
   * то вывовдить язык системы в селектор
   */
  const userLangSelected = userLang ? userLang : window.busytable.fallbackLang

  const isVenue = venue && venue.here

  const handleLanguageChange = e => {
    if (e.target.value) {
      dispatch(setLang(e.target.value))
    }
    postEvent('settings-language', 'involvement', { value: e.target.value })
  }
  const scanHandler = () => {
    postEvent('settings-scan', 'involvement')
    setScan(true)
  }
  // {en: "EN", ru: "RU"} ///example

  const renderScan = () => {
    if (scan && !isNative) {
      return <CodeScan />
    }
  }

  const renderTableScanView = () => {
    if (!preferences?.disableInVenueOrders && !isNative && !preferences?.withQrMenuMode) {
      return (
        <div onClick={scanHandler} className={s.tableWrap}>
          <div className={s.table}>
            <div className={s.table_ind}>
              <div>{tableNum === 0 ? <NoTable /> : tableNum}</div>
            </div>
            <TableVector className={s.tableVec} />
          </div>
        </div>
      )
    } else if (isNative && venueId !== 0 && isVenue) {
      return (
        <div className={s.tableWrap}>
          <p>
            {!tableNum ? (
              <span onClick={() => history.push(`/v/${venueId}`)}>{t('tableNumber.selectTableNumber')}</span>
            ) : (
              <div style={{ backgroundImage: 'none' }} className={s.table_ind}>
                {tableNum}
              </div>
            )}
          </p>
        </div>
      )
    }
  }

  const getScanViewStatus = () => {
    if (isNative && !tableNum) {
      return s.setWrapper
    } else if (demoVariables?.codeScanBg || variables?.codeScanBg) {
      return s.setWrapperBg
    }
    return s.setWrapper
  }

  const renderTableButton = () => {
    if (isVenue && !preferences?.withQrMenuMode) {
      return (
        <div className={[s.tutorialWrapper, 'caption1'].join(' ')}>
          <div
            onClick={() => {
              if (tableNum !== 0) {
                postEvent('settings-btn_notable', 'involvement', { table: 'none' })
                dispatch(setTableNum(0))
              }
            }}
            className={[s.notable, tableNum === 0 ? s.notable_gray : ''].join(' ')}
          >
            {t('tableless')}
          </div>
        </div>
      )
    }
  }

  const renderLanguagesSelector = () => {
    const keys = Object.keys(languages)
    if (keys.length === 1) return
    const renderLanguageOptions = (possibleLanguages = { en: 'EN', ru: 'RU' }) => {
      return keys.map(key => {
        return (
          <option key={key} value={key}>
            {possibleLanguages[key]}
          </option>
        )
      })
    }
    return (
      <div className={s.buttonWrapper} style={{ maxWidth: '70px' }}>
        <div style={{ padding: '0px', boxShadow: 'none' }} className={[s.item2, s.selectWrapper].join(' ')}>
          <select
            style={{
              paddingLeft: '8px',
              height: '40px',
              width: '70px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            className={s.dropdp1}
            value={userLangSelected}
            onChange={handleLanguageChange}
          >
            <option value=''>{t('selectLanguage')}</option>
            {renderLanguageOptions(languages)}
          </select>
        </div>
      </div>
    )
  }

  return (
    <div className={'confirmation'}>
      {renderScan()}
      <MenuBar
        title={t('settings')}
        icon='close'
        url='/'
        eventCb={() => {
          postEvent('settings-btn_close')
        }}
      />
      <div className={'content'}>
        <div className={'contentWrapper'}>
          {/* <div className={s.tutorial}>{t('pressToScan')}</div> */}
          <div className={getScanViewStatus()}>
            {renderTableScanView()}
            {renderTableButton()}
          </div>
          {/* Список кнопок-урлов в настройках */}
          <div className={s.list}>
            <div
              onClick={() => {
                postEvent('settings-profile', 'involvement')
                history.push('/profile')
              }}
              className={s.item}
            >
              <SettingsIcon iconSlug='user' />
              <div data-cy='settingsUser' className={[s.userText, 'body1'].join(' ')}>
                {user && user.name ? user.name : t('profile')}
              </div>
            </div>
            {venueId !== 0 && isVenue && !preferences?.withQrMenuMode ? (
              <div
                onClick={() => {
                  postEvent('settings-input_table', 'involvement')
                  history.push(`/v/${venueId}`)
                }}
                className={s.item}
              >
                <SettingsIcon iconSlug='table' />
                <div data-cy='settingsTable' className={[s.userText, 'body1'].join(' ')}>
                  {t('inputTable')}
                </div>
              </div>
            ) : (
              ''
            )}
            <div
              onClick={() => {
                postEvent('settings-venues', 'involvement')
                history.push('/venues')
              }}
              className={s.item}
            >
              <SettingsIcon iconSlug='venue' />
              <div data-cy='settingsVenue' className={[s.userText, 'body1'].join(' ')}>
                {t('venueSelect')}
              </div>
            </div>
            <div className={s.vrBrand} />
            {!preferences?.withQrMenuMode && (
              <div
                onClick={() => {
                  postEvent('settings-history', 'involvement')
                  history.push('/payments')
                }}
                className={s.item}
              >
                <SettingsIcon iconSlug='order' />
                <div data-cy='settingsHistory' className={[s.userText, 'body1'].join(' ')}>
                  {t('orderHistory')}
                </div>
              </div>
            )}
            <div
              onClick={() => {
                postEvent('settings-rate', 'involvement')
                history.push('/contact')
              }}
              className={s.item}
            >
              <SettingsIcon iconSlug='contact' />
              <div data-cy='settingsFeedback' className={[s.userText, 'body1'].join(' ')}>
                {t('contactUs')}
              </div>
            </div>
            {/* 
            {preferences?.withTips ? (
              <div
                onClick={() => {
                  postEvent('settings-rate', 'involvement')
                  history.push('/tips')
                }}
                className={s.item}
              >
                <SettingsIcon iconSlug='tipsIcon' />
                <div className={[s.userText, 'body1'].join(' ')}>{t('review.leaveTips')}</div>
              </div>
            ) : null} */}
            <div className={s.vrBrand} />
            {customPages.map((page, i) => (
              <div
                key={page.slug}
                onClick={() => {
                  postEvent(`settings-${page.slug}`, 'involvement')
                  history.push(`/page/${page.slug}`)
                }}
                className={s.item}
              >
                <SettingsIcon icon={page.icon} />
                <div data-cy={`settingsCustomPage-${i}`} className={[s.userText, 'body1'].join(' ')}>
                  {t(page.label)}
                </div>
              </div>
            ))}
            {/* <div
              onClick={() => {
                postEvent('settings-about', 'involvement')
                history.push('/about')
              }}
              className={s.item}
            >
              <SettingsIcon iconSlug='about' />
              <div className={[s.userText, 'body1'].join(' ')}>{t('about')}</div>
            </div> */}
            {/* <div
              onClick={() => {
                postEvent('settings-job', 'involvement')
                history.push('/job')
              }}
              className={s.item}
            >
              <div className={s.jobIcon}></div>
              <div className={[s.userText, ui.body1].join(' ')}>{t('jobbrand')}</div>
            </div> */}
            {/* <div
              onClick={() => {
                postEvent('settings-work', 'involvement')
                history.push('/work')
              }}
              className={s.item}
            >
              <div className={s.workIcon}></div>
              <div className={[s.userText, ui.body1].join(' ')}>{t('jobbusytable')}</div>
            </div> */}
          </div>
          {/* Кнопки "телефон" и "телеграм" */}
          <div className={s.buttonWrapper}>
            {((!venue && phone) || venue?.phone) && (
              <SetButton
                icon={'phone'}
                href={`tel:${!venue ? phone : venue.phone.replace(/ /g, '')}`}
                onClick={() => postEvent('settings-btn_call_us', 'involvement')}
                text={!venue ? phone : venue.phone.replace(/ /g, '')}
              />
            )}
            {telegram && (
              <SetButton
                icon={'telegram'}
                href={`tg://resolve?domain=${telegram}`}
                onClick={() => postEvent('settings-btn_text_us', 'involvement')}
                text={'Telegram'}
              />
            )}
          </div>
          {/* Селектор языка */}
          {renderLanguagesSelector()}
          <Background withPattern />
          {/* отступ */}
          <div className={s.build}>{window.busytable.build}</div>
          <div style={{ marginBottom: '100px' }} className={s.buttonWrapper}>
            <div style={{ padding: '0px' }} className={[s.item2, s.selectWrapper].join(' ')} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
