import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import clsx from 'classnames'
import { isEmpty } from 'ramda'
import { useTranslation } from 'src/hooks/useTranslation'
import { getVenueId, setCurrentCategory } from 'src/state/location'
import { getCheck } from 'src/state/user/selectors'
import Checkout from 'src/components/Checkout'
import { Loading } from 'src/components/shared/components/Loading'
import { ScrollWrapper } from 'src/components/ui/ScrollWrapper'
import { SnackBanner } from 'src/components/ui/SnackBanner'
import { Wrapper } from 'src/components/ui/Wrapper'
import { postEvent } from 'src/api'
import Background from 'src/components/ui/Background.tsx'
import useThemeStyles from 'src/hooks/useThemeStyles'
import useCurrentCategories from 'src/hooks/useCurrentCategories.ts'
import useCurrentDishes from 'src/hooks/useCurrentDishes.ts'
import useAppPreferences from 'src/hooks/useAppPreferences'
import CatTabs from './components/CatTabs'
import { MenuNavbar } from './components/MenuNavbar'
import { SubcatTabs } from './components/SubcatTabs'
import Cards from './components/Cards'
import s from './styles.module.scss'
import TagsFilter from './components/TagsFilter'
import useDishesTags from '../../hooks/useDishesTags'
import useDebounce from '../../hooks/useDebounce'
import { FAVORITES_CATEGORY_ID, POPULAR_CATEGORY_ID } from '../../constants'
import FavoritesTab from './components/FavoritesTab'
import useDishesFilter from '../../hooks/RichMenu/useDishesFilter'
import useActiveDishes from '../../hooks/useActiveDishes'
import TabBarMenu from 'src/components/TabBarMenu'

const RichMenu = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { getActiveCategory } = useCurrentCategories()
  const { currentCategoryId: activeTab, currentSubcategoryId: activeSubtab } = getActiveCategory()

  const { dishes } = useCurrentDishes()
  const check = useSelector(getCheck)
  const { t } = useTranslation()

  const handleTabClick = useCallback(
    cat => {
      postEvent('richmenu-category', 'searching', { name: t(cat.name), id: cat.id })
      dispatch(setCurrentCategory(cat.id))
    },
    [dispatch, t]
  )

  const handleSubtabClick = useCallback(
    subcat => {
      postEvent('richmenu-subcategory', 'searching', { name: t(subcat.name), id: subcat.id })
      dispatch(setCurrentCategory(subcat.id))
    },
    [dispatch, t]
  )

  const handleCardClick = useCallback(
    dish => {
      history.push(`/dish/${dish.id}`)
      postEvent('richmenu-dishcard', 'ordering', { name: t(dish.name), id: dish.id })
    },
    [history, t]
  )

  const handleLeftClick = useCallback(() => {
    history.push(`/settings`)
    postEvent('richmenu-btn_settings', 'default')
  }, [history])

  return isEmpty(dishes) ? (
    <Loading />
  ) : (
    <>
      <Content
        {...{
          history,
          handleTabClick,
          activeTab,
          handleSubtabClick,
          activeSubtab,
          handleCardClick,
          handleLeftClick
        }}
      />
      {check ? <Checkout history={history} /> : ''}
    </>
  )
}

const maxSize = window.innerWidth > 1025 ? 48 : 0
const minSize = window.innerWidth > 1025 ? 0 : -48

const Content = React.memo(
  ({ handleTabClick, activeTab, handleSubtabClick, activeSubtab, handleCardClick, handleLeftClick }) => {
    const { t } = useTranslation()
    const [ref, inView] = useInView({ threshold: 1 })
    const [lastScroll, setLastScroll] = useState(0)
    const [navbarHeight, setNavbarHeight] = useState(0)
    const { categories: cats } = useCurrentCategories()
    const [isSearch, setIsSearch] = useState(false)
    const [loyalty, setLoyalty] = useState(false)
    const [searchKeyword, setSearchKeyword] = useState('')
    const scrollRef = useRef(null)
    const dishListRef = useRef(null)
    const venueId = useSelector(getVenueId)
    const { holidayModifications, preferences } = useAppPreferences()
    const debouncedSearchValue = useDebounce(searchKeyword, 300)
    const activeDishes = useActiveDishes(activeTab, activeSubtab)
    const { filteredDishes, filterDishesByKeyword, filterDishesByTags } = useDishesFilter(activeDishes)
    const [isFullTabBarView, setIsFullTabBarView] = useState(true)
    const [isFullTabBarSearch, setIsFullTabBarSearch] = useState(false)

    const { navbarVariables } = useThemeStyles()
    const { demoTheme } = useSelector(state => state.demo)
    const [tab, settab] = useState(activeTab)
    const scrollDiff = useRef(0)
    const scrollStack = useRef(0)
    const isExpandable = preferences?.withExpandableNavbar || false

    // preferences.withExpandableNavbar

    useEffect(() => {
      if (isSearch && debouncedSearchValue.length) {
        filterDishesByKeyword(debouncedSearchValue)
        postEvent('richmenu-navbar-btn_search', 'searching', { value: debouncedSearchValue })
      } else {
        filterDishesByKeyword('')
      }
    }, [isSearch, debouncedSearchValue])

    useEffect(() => {
      settab(activeTab)
    }, [activeTab])

    const handleFullTabBarView = useCallback(condition => {
      if (isExpandable) {
        setIsFullTabBarView(condition)
      }
    }, [])

    const handleSearchKeyword = text => setSearchKeyword(text)
    const handleIsFullTabBarViewSearch = condition => setIsFullTabBarSearch(condition)

    // **
    // Убрать эту функцию и получить её же из пропсов, для перехода в лояльность
    // **
    const handleRightClick = () => {
      setLoyalty(true)
    }

    // **
    // Убрать useEffect для лояльности
    // **
    useEffect(() => {
      let interv = setTimeout(() => {
        setLoyalty(false)
      }, 5000)
      return () => {
        clearTimeout(interv)
      }
    }, [loyalty])

    useEffect(() => {
      scrollRef.current.scrollTop = 0
    }, [debouncedSearchValue])

    const handleScroll = ev => {
      scrollDiff.current = ev - lastScroll
      if (ev - lastScroll > 0) {
        scrollStack.current = minSize
      } else {
        scrollStack.current = maxSize
      }
      if (ev < 40) {
        scrollStack.current = ev
      }
      setLastScroll(ev)
    }

    const handleTabClickWrapper = useCallback(
      cat => {
        handleTabClick(cat)
        scrollRef.current.scrollTop = 0
      },
      [scrollRef.current, handleTabClick]
    )

    const handleSubTabClickWrapper = subcat => {
      handleSubtabClick(subcat)
      scrollRef.current.scrollTop = 0
    }

    const variants = {
      visible: {
        transition: {
          staggerChildren: 0.04,
          delayChildren: 0.1
        }
      },
      hidden: {
        transition: {
          staggerChildren: 0.05,
          staggerDirection: -1
        }
      }
    }

    const navBarPadding = useMemo(() => {
      if (isExpandable) {
        if (isFullTabBarSearch) {
          return '64px'
        } else {
          return `${navbarHeight + 16}px`
        }
      } else if (navbarHeight > 0 && cats.length >= 2 && !isSearch) {
        return `${navbarHeight + 16}px`
      } else {
        return null
      }
    }, [isExpandable, isFullTabBarSearch, navbarHeight, cats, isSearch])

    return (
      <Wrapper
        ref={scrollRef}
        onScroll={e => {
          if (window.innerWidth <= 1025 && !isExpandable) {
            console.log('here')
            handleScroll(e.target.scrollTop)
          }
        }}
        zIndex='0'
        className={clsx(
          s.richMenuWrapper,
          holidayModifications?.currentVariant &&
            holidayModifications?.variants?.[holidayModifications.currentVariant]?.customBackground &&
            s[`richMenuWrapper_${holidayModifications?.currentVariant}`]
        )}
      >
        <div ref={ref} />
        <SnackBanner
          icon
          visible={loyalty}
          className={[s.customSnackPosition]}
          background={'#000000'}
          text={'Еще не готово!'}
        />
        {
          <div
            ref={el => {
              if (el?.clientHeight > navbarHeight) {
                setNavbarHeight(el.clientHeight)
              }
            }}
            style={{
              top: scrollStack.current + 'px',
              '--viewBg': demoTheme?.navbarVariables?.navbarBackground || navbarVariables?.navbarBackground || null
            }}
            className={`${s.stickyTabs} ${isExpandable && isFullTabBarView ? s.stickyTabsExpanded : ''} ${
              isExpandable ? s.stickyTabsExpandable : ''
            }`}
          >
            <MenuNavbar
              setIsSearch={setIsSearch}
              setSearchKeyword={setSearchKeyword}
              scrollTop={scrollDiff.current}
              handleLeftClick={handleLeftClick}
              handleRightClick={handleRightClick}
              setLoyalty={setLoyalty}
              search
            />

            {isSearch ? (
              ''
            ) : (
              <CatTabs
                venueId={venueId}
                handleTabClick={handleTabClickWrapper}
                activeTab={activeTab}
                inView={inView}
                handleSubtabClick={handleSubTabClickWrapper}
                activeSubtab={activeSubtab}
                isFullTabBarView={isFullTabBarView}
                handleFullTabBarView={handleFullTabBarView}
              />
            )}
          </div>
        }
        {
          <div
            style={{
              paddingTop: navBarPadding
            }}
            className={cats.length < 2 ? s.stickyTabs2 : isSearch ? s.stickyTabs_pad_search : s.stickyTabs_pad}
          />
        }
        {isSearch || isExpandable ? null : (
          <SubcatTabs {...{ handleSubtabClick: handleSubTabClickWrapper, activeSubtab, activeTab }} />
        )}
        {isExpandable && !isSearch && ![FAVORITES_CATEGORY_ID, POPULAR_CATEGORY_ID].includes(activeTab) && (
          <TagsFilter dishes={activeDishes} onChange={filterDishesByTags} />
        )}
        {/* <PromoCard /> */}
        <ScrollWrapper pathname={activeTab + ' ' + activeSubtab} />
        <motion.div className={s.cardsWrapper} key={tab} variants={variants} initial='hidden' animate='visible'>
          {activeTab === FAVORITES_CATEGORY_ID && !debouncedSearchValue.length && <FavoritesTab />}
          {(activeTab !== FAVORITES_CATEGORY_ID || isSearch) && (
            <Cards
              dishes={filteredDishes}
              searchMode={Boolean(debouncedSearchValue)}
              handleCardClick={handleCardClick}
            />
          )}
        </motion.div>
        {/* <motion.div className={s.cardsWrapper} key={tab} variants={variants} initial='hidden' animate='visible'>
          {<Cards {...{ activeTab, searchKeyword, filteredDishes, handleCardClick }} />}
        </motion.div> */}
        <Background withPattern />
        {isExpandable && isFullTabBarView && (
          <TabBarMenu
            handleIsFullTabBarViewSearch={handleIsFullTabBarViewSearch}
            handleSearchKeyword={handleSearchKeyword}
            handleFullTabBarView={handleFullTabBarView}
          />
        )}
      </Wrapper>
    )
  }
)

// const filteredDishes =
//   searchKeyword !== ''
//     ? dishes
//         .filter(e => filterByKeyword(e, searchKeyword, t))
//         .filter(dish => (venueId !== '4' ? dish.extended_menu !== true : true))
//     : showDishes

export default RichMenu
