import { useMemo } from 'react'
import { useSelector } from 'src/state/hooks'
import { getFavorites } from 'src/state/user'
import { getHistory } from 'src/state/history/selectors'
import { FavoriteDish, IDish, IOrder, SelectedOptionType } from 'src/types'
import { findFavoritesDishes, findFavoritesOrders } from 'src/utils/favorites'
import { DISHES_FAVORITES_COUNT, PAYMENTS_FAVORITES_COUNT, PAYMENTS_TO_FIND_FAVORITES } from 'src/constants'
import useCurrentDishes from './useCurrentDishes'
import { filterExistedOptionsForDish, onlyUnique } from '../utils'
import { getOptionsets } from '../state/menu'
import { getUniqueKeyByDishAndOptions } from '../utils/orders'
import useOptionSetApi from './useOptionSetApi'

type FavoriteDishWithOptions = {
  dish: IDish | undefined
  options: SelectedOptionType[]
  key: string
  isFavorite?: boolean
}
type CategoryFavoritesWithOptions = { [key in number]: FavoriteDishWithOptions[] }
type FindFavoriteDishWithOptions = (dish: IDish, options?: SelectedOptionType[]) => FavoriteDishWithOptions | null
type UseFavoritesDishesValue = {
  userTopOrders: TopPaymentOrders[]
  userFavoriteOrders: IOrder[]
  findFavoriteDishWithOptions: FindFavoriteDishWithOptions
  findTopDishWithOptions: FindFavoriteDishWithOptions
  allSortedFavoritesDishes: FavoriteDishWithOptions[]
}
type TopPaymentOrders = {
  orders: IOrder[]
}

type UseFavoritesDishesHook = () => UseFavoritesDishesValue

const catIdKey = (id: number) => `_${id}` //Fix, Object.fromEntries sort entries by default

const useFavoritesDishes: UseFavoritesDishesHook = function() {
  const userFavoriteDishes = useSelector(getFavorites)
  const historyPayments = useSelector(getHistory)
  const optionSets = useSelector(getOptionsets)

  const { dishes, isDishInStopList, isDishExist } = useCurrentDishes()
  const { isOptionExist } = useOptionSetApi()

  const paymentsToProcess =
    historyPayments.length > PAYMENTS_TO_FIND_FAVORITES
      ? historyPayments.slice(0, PAYMENTS_TO_FIND_FAVORITES)
      : historyPayments

  const userTopOrders: TopPaymentOrders[] = useMemo(() => {
    const sortedPaymentOrders = findFavoritesOrders(paymentsToProcess)
    let result = [] as TopPaymentOrders[]
    for (let i = 0; i < sortedPaymentOrders.length; i++) {
      if (result.length === PAYMENTS_FAVORITES_COUNT) {
        break
      }
      const currentOrders = sortedPaymentOrders[i]
      const isValidOrders = !currentOrders.some(
        order =>
          isDishInStopList?.(order.dishId) ||
          !isDishExist?.(order.dishId) ||
          order.options?.some(option => !isOptionExist(option))
      )
      if (isValidOrders) {
        result = [...result, { orders: currentOrders }]
      }
    }

    return result
  }, [isDishInStopList, isDishExist, isOptionExist, paymentsToProcess])

  const userFavoriteOrders: IOrder[] = useMemo(() => {
    const sortedOrders = findFavoritesDishes(paymentsToProcess)
    let result = [] as IOrder[]
    for (let i = 0; i < sortedOrders.length; i++) {
      if (result.length === DISHES_FAVORITES_COUNT) {
        break
      }
      const currentOrder = sortedOrders[i]
      const isValidOrder =
        !isDishInStopList?.(currentOrder.dishId) &&
        isDishExist?.(currentOrder.dishId) &&
        !currentOrder.options?.some(option => !isOptionExist(option))

      if (isValidOrder) {
        result = [...result, currentOrder]
      }
    }

    return result
  }, [isDishInStopList, isDishExist, isOptionExist, paymentsToProcess])

  const findFavoriteDishWithOptions: FindFavoriteDishWithOptions = useMemo(
    () => (dish, options) => {
      if (!dish) {
        return null
      }

      const dishWithSameParentSizeId = dishes?.find(dishItem => dishItem.parentSizeId === dish.id)
      const dishFromFavorites = userFavoriteDishes.find((favItem: FavoriteDish) => {
        if (options) {
          return (
            (favItem.id === dish.id || favItem.id === dishWithSameParentSizeId?.id) &&
            getUniqueKeyByDishAndOptions(favItem.id, favItem.options) === getUniqueKeyByDishAndOptions(dish.id, options)
          )
        } else {
          return favItem.id === dish.id || favItem.id === dishWithSameParentSizeId?.id
        }
      })
      const existedOptions =
        filterExistedOptionsForDish(
          dishFromFavorites?.options,
          dishFromFavorites?.id === dish.id ? dish.recipeId : dishWithSameParentSizeId?.recipeId,
          optionSets
        ) || []

      const currentDish = dishFromFavorites?.id === dish.id ? dish : dishWithSameParentSizeId

      if (currentDish && dishFromFavorites?.options.length === existedOptions.length) {
        return {
          dish: currentDish,
          options: existedOptions,
          key: getUniqueKeyByDishAndOptions(currentDish.id, existedOptions),
          isFavorite: true
        }
      }
      return null
    },
    [dishes, userFavoriteDishes, optionSets]
  )

  const findTopDishWithOptions: FindFavoriteDishWithOptions = useMemo(
    () => (dish, options) => {
      if (!dish) {
        return null
      }

      const dishWithSameParentSizeId = dishes?.find(dishItem => dishItem.parentSizeId === dish.id)
      const orderFromTop = userFavoriteOrders.find((favItem: IOrder) => {
        if (options) {
          return (
            (favItem.dishId === dish.id || favItem.dishId === dishWithSameParentSizeId?.id) &&
            getUniqueKeyByDishAndOptions(favItem.dishId, favItem.options || []) ===
              getUniqueKeyByDishAndOptions(dish.id, options)
          )
        } else {
          return favItem.dishId === dish.id || favItem.dishId === dishWithSameParentSizeId?.id
        }
      })

      const existedOptions =
        filterExistedOptionsForDish(
          orderFromTop?.options,
          orderFromTop?.dishId === dish.id ? dish.recipeId : dishWithSameParentSizeId?.recipeId,
          optionSets
        ) || []
      const currentDish = orderFromTop?.dishId === dish.id ? dish : (dishWithSameParentSizeId as IDish)

      const sameFavoriteDish = findFavoriteDishWithOptions(currentDish, existedOptions)
      if (currentDish && existedOptions.length === orderFromTop?.options?.length) {
        return (
          sameFavoriteDish || {
            dish: currentDish,
            options: existedOptions,
            key: getUniqueKeyByDishAndOptions(currentDish.id, existedOptions)
          }
        )
      }
      return null
    },
    [dishes, findFavoriteDishWithOptions, userFavoriteOrders, optionSets]
  )

  const favoriteDishesCategoriesMap: CategoryFavoritesWithOptions = useMemo(() => {
    return userFavoriteDishes.reduce((result: CategoryFavoritesWithOptions, favItem: FavoriteDish) => {
      const currentDish = dishes?.find(dish => dish.id === favItem.id || dish.parentSizeId === favItem.id) as IDish
      const catId = currentDish?.subcategoryId || currentDish?.categoryId
      const favDish = findFavoriteDishWithOptions(currentDish, favItem.options)
      if (catId && catIdKey(catId) in result && favDish) {
        // @ts-ignore
        return { ...result, [catIdKey(catId)]: [...result[catIdKey(catId)], favDish] }
      } else if (catId && favDish) {
        return { ...result, [catIdKey(catId)]: [favDish] }
      } else {
        return result
      }
    }, {})
  }, [userFavoriteDishes, findFavoriteDishWithOptions, dishes])

  const favoriteOrdersCategoriesMap: CategoryFavoritesWithOptions = useMemo(() => {
    const categoriesMap: CategoryFavoritesWithOptions = userFavoriteOrders.reduce((result, order) => {
      const currentDish = dishes?.find(dish => dish.id === order.dishId || dish.parentSizeId === order.dishId) as IDish
      const catId = currentDish?.subcategoryId || currentDish?.categoryId
      const topDish = findTopDishWithOptions(currentDish, order.options || [])
      if (catId && catIdKey(catId) in result && topDish) {
        // @ts-ignore
        return { ...result, [catIdKey(catId)]: [...result[catIdKey(catId)], topDish] }
      } else if (catId && topDish) {
        return { ...result, [catIdKey(catId)]: [topDish] }
      } else {
        return result
      }
    }, {})

    const sortedByOrdersCount = Object.entries(categoriesMap).sort((b, a) => a[1].length - b[1].length)
    return Object.fromEntries(sortedByOrdersCount)
  }, [userFavoriteOrders, findTopDishWithOptions, dishes])

  const allSortedFavoritesDishes: FavoriteDishWithOptions[] = useMemo(() => {
    const uniqueCategories = [
      ...Object.keys(favoriteOrdersCategoriesMap),
      ...Object.keys(favoriteDishesCategoriesMap)
    ].filter(onlyUnique)
    const allFavoriteDishes = uniqueCategories.reduce((result: FavoriteDishWithOptions[], current) => {
      // @ts-ignore
      const favoriteDishes = favoriteDishesCategoriesMap[current]
      // @ts-ignore
      const favoriteOrderDishes = favoriteOrdersCategoriesMap[current]
      return [...result, ...(favoriteOrderDishes || []), ...(favoriteDishes || [])]
    }, [])
    return allFavoriteDishes
      .map(favDish => favDish.key)
      .filter(onlyUnique)
      .map(key => allFavoriteDishes.find(favDish => favDish.key === key)) as FavoriteDishWithOptions[]
  }, [favoriteDishesCategoriesMap, favoriteOrdersCategoriesMap])

  return {
    userTopOrders,
    userFavoriteOrders,
    findFavoriteDishWithOptions,
    findTopDishWithOptions,
    allSortedFavoritesDishes
  }
}

export default useFavoritesDishes
